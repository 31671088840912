<template>
  <div class="page-content pt-5">
    <section class="bg-01">
      <div class="text-center">
        <h1 class="title">Pricing</h1>
      </div>
      <div class="container pricing-tab">
        <div class="row flex">
          <div class="col-md-3">
            <router-link :to="{ name: 'pricing.sms' }">
              <div class="card py-3">
                <div class="text-center">
                  <img src="/images/feature/07.png" alt="image" />
                </div>
                <h4 class="text-center mt-2">Bulk SMS</h4>
              </div>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{ name: 'pricing.voice' }">
              <div class="card py-3">
                <div class="text-center">
                  <img src="/images/feature/04.png" alt="image" />
                </div>
                <h4 class="text-center mt-2">Voice</h4>
              </div>
            </router-link>
          </div>
          <!-- <div class="col-md-3">
            <router-link :to="{ name: 'pricing.airtime' }">
              <div class="card py-3">
                <div class="text-center">
                  <img src="/images/feature/02.png" alt="image" />
                </div>
                <h4 class="text-center mt-2">Airtime</h4>
              </div>
            </router-link>
          </div> -->
          <div class="col-md-3">
            <router-link :to="{ name: 'pricing.ussd' }">
              <div class="card py-3">
                <div class="text-center">
                  <img src="/images/feature/06.png" alt="image" />
                </div>
                <h4 class="text-center mt-2">USSD</h4>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <router-view />
  </div>
</template>

<script>
export default {};
</script>
